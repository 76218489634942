@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap');

/* Root Variables */
:root {
  --mainDark: #040B14;
  --mainGrey: rgba(109, 109, 109);
  --mainLightGrey: rgba(172, 172, 172);
  --mainWhite: #fff;
  --mainBlue: #5892dd;
  --mainGreen: #6dc77a;
  --mainRed: #dd2427;
}

/* Body Content */
body {
  font-family: 'Poppins', sans-serif;
  background-color: whitesmoke;
}

/* Set Flex Box App Structure */
.app-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.container#main {
  margin-top: 3rem;
  margin-bottom: 4rem;
}

/* BLOG CSS */
/* NOTE: This all needs to be transformed into styled components / global color variables */
.blog-post {
  -webkit-transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s;
}

.blog-post .blog-img .overlay,
.blog-post .blog-img .post-meta {
  position: absolute;
  opacity: 0;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.blog-post .blog-img .overlay {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.blog-post .blog-img .post-meta {
  bottom: 5%;
  right: 5%;
  z-index: 1;
}

.blog-post .blog-img .post-meta .read-more:hover {
  color: var(--mainGreen) !important;
  text-decoration: none;
}


.blog-post .content h1, .blog-post .content h2, .blog-post .content h3, .blog-post .content h4, .blog-post .content h5, .blog-post .content h6 {
  line-height: 1.2;
}

.blog-post .content .title {
  font-size: 18px;
}

.blog-post .content .title:hover {
  color: var(--mainGreen) !important;
}

.blog-post .content .author .name:hover {
  color: var(--mainGreen) !important;
}

.blog-post:hover {
  -webkit-transform: translateY(-7px);
          transform: translateY(-7px);
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}

.blog-post:hover .blog-img .overlay {
  opacity: 0.65;
}

.blog-post:hover .blog-img .post-meta {
  opacity: 1;
}

.blog-post .post-meta .like i,
.profile-post .like i {
  -webkit-text-stroke: 2px var(--mainRed);
  -webkit-text-fill-color: transparent;
}

.blog-post .post-meta .like:active i, .blog-post .post-meta .like:focus i,
.profile-post .like:active i,
.profile-post .like:focus i {
  -webkit-text-stroke: 0px var(--mainRed);
  -webkit-text-fill-color: var(--mainRed);
}

.avatar.avatar-ex-sm {
    height: 36px;
}
.shadow {
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15) !important;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15) !important;
}

.text-muted {
    color: var(--mainGrey) !important;
}

.para-desc {
    max-width: 600px;
}

.section-title .title {
    letter-spacing: 0.5px;
    font-size: 30px;
}